
import Vue from 'vue';
import { required, minLength, maxLength } from 'vuelidate/dist/validators.min';

import SeparatedDigitsInput from 'Control/SeparatedDigitsInput.vue';
import { ISSUE_MFA_ACTIONS, MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';

enum InputTypes {
    email = 'email',
    totp = 'totp',
}

interface Data {
    InputTypes: any;
    requestNewCodeTimeLeft: number;
    countdownIntervalId: any;
    formData: { email: string; totp: string; emailErrors: boolean; totpErrors: boolean; };
}

interface Methods {
    sendNewCode: () => void;
    startRequestNewCodeCountdown: () => void;
    clearIntervalId: () => void;
    requestNewCode: () => void;
    returnCodes: () => void;
    onChange: (value: string, idPrefix: string) => void;
    goBack: () => void;
    onPressEnter: (data: any) => void;
}

interface Computed {
    isVerificationInProgress: boolean;
    lastRoute: string;
}

export default Vue.extend<Data, Methods, Computed>({
    name: 'MFA_email-totp',
    components: {
        SeparatedDigitsInput,
        Icon,
        Button,
    },
    data() {
        return {
            InputTypes,
            requestNewCodeTimeLeft: 0,
            countdownIntervalId: null,

            formData: {
                email: '',
                totp: '',
                emailErrors: false,
                totpErrors: false,
            },
        };
    },
    validations() {
        return {
            formData: {
                email: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(6),
                },
                totp: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(6),
                },
            },
        };
    },
    computed: {
        isVerificationInProgress() {
            return this.$store.state.Auth.isVerificationInProgress;
        },
        lastRoute() {
            return this.$store.state.Auth.lastRoute;
        },
    },
    methods: {
        async sendNewCode() {
            await this.$store.dispatch('Auth/issueMfa', {
                mfaType: MFA_ENROLL_FACTOR_TYPES.EMAIL,
                action: ISSUE_MFA_ACTIONS.MFA_DISABLE,
            });
        },
        startRequestNewCodeCountdown() {
            this.requestNewCodeTimeLeft = 30;

            const countdownIntervalId = setInterval(() => {
                this.requestNewCodeTimeLeft -= 1;

                if (this.requestNewCodeTimeLeft === 0) {
                    clearInterval(countdownIntervalId);
                }
            }, 1000);
            this.countdownIntervalId = countdownIntervalId;
        },
        clearIntervalId() {
            clearInterval(this.countdownIntervalId);
        },
        async requestNewCode() {
            this.startRequestNewCodeCountdown();
            await this.sendNewCode();
        },
        async returnCodes() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.clearIntervalId();
                this.startRequestNewCodeCountdown();
                await this.$store.dispatch('Auth/resolveGetMFATokenChallenge', { emailCode: this.formData.email, totpCode: this.formData.totp });

                if (this.$refs.EmailInput) {
                    (this.$refs.EmailInput as any).clearInput();
                }
                if (this.$refs.TotpInput) {
                    (this.$refs.TotpInput as any).clearInput();
                }
                this.$v.$reset();
            } else {
                if (this.$v.formData.email.$invalid) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Please enter 6 digits code from email');
                }
                if (this.$v.formData.totp.$invalid) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Please enter 6 digits code from authenticator');
                }
            }
        },
        onPressEnter(e) {
            if (e.key === 'Enter') {
                this.returnCodes();
            }
        },
        onChange(value, idPrefix) {
            this.formData[idPrefix] = value;
            try {
                this.$v.reset();
            } catch {
                // library error
            }
        },
        goBack() {
            if (this.lastRoute) {
                this.$router.push(this.lastRoute).catch(() => { /* navigation error */ });
                return;
            }

            if (window.history.length > 2) {
                this.$router.back();
            } else {
                this.$router.push('/terminal').catch(() => { /* navigation error */ });
            }
        },
    },
    mounted() {
        document.addEventListener('keydown', this.onPressEnter);

        this.startRequestNewCodeCountdown();
        if (!this.isVerificationInProgress) {
            this.$router.replace(this.lastRoute ?? '/terminal').catch(() => { /* navigation error */ });
        }
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onPressEnter);
    },
});
